<template>
  <div
    ref="root"
    class="relative rounded-[12px] overflow-hidden"
    @click="onClick"
  >
    <video
      ref="video"
      disablepictureinpicture
      muted
      loop
      preload="auto"
      :width="width"
      :height="height"
      :poster="poster"
    >
      <source
        v-for="src of sources"
        :src="src"
        :type="`video/${src.split('.').at(-1)}`"
      />
    </video>

    <div
      class="absolute inset-0 flex items-center justify-center rounded-[12px]"
      :class="{
        'opacity-100': !isPlaying,
        'opacity-0': isPlaying,
      }"
    >
      <button
        class="absolute inset-0 flex justify-center items-center w-full cursor-pointer hover:opacity-80 transition-opacity duration-300"
      >
        <svg
          width="100"
          height="100"
          viewBox="0 0 100 100"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="relative z-20 rounded-full md:translate-y-3"
        >
          <circle
            cx="50"
            cy="50"
            r="48"
            fill="white"
            fill-opacity="0.32"
            stroke="white"
            stroke-width="4"
          ></circle>
          <path
            d="M60 50.2679C61.3333 51.0377 61.3333 52.9623 60 53.7321L46.5 61.5263C45.1667 62.2961 43.5 61.3338 43.5 59.7942L43.5 44.2058C43.5 42.6662 45.1667 41.7039 46.5 42.4737L60 50.2679Z"
            fill="white"
          ></path>
        </svg>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

defineProps<{
  poster: string;
  width: number;
  height: number;
  sources: string[];
}>();

const root = ref<HTMLElement | null>(null);
const video = ref<HTMLVideoElement | null>(null);

const isPlaying = ref(false);
function onClick() {
  if (video.value == null) {
    return;
  }

  if (isPlaying.value) {
    video.value.pause();
    video.value.currentTime = 0;
    isPlaying.value = false;
  } else {
    video.value.play();
    isPlaying.value = true;
  }
}
</script>
